import React, { } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';

function Header() {


  return (
    <header className="header">
      <h1 className="logo">
        <Link to="/" className="logo-link">
          <img src="/logo2.png" alt="Logo" className="logo-img" style={{ borderRadius: "50%" }} />
          Musga Player
        </Link>
      </h1>

      <nav>
        <ul>
          <li><Link to="/">Home</Link></li>
          <li><Link to="/download">Baixe já</Link></li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;
